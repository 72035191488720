/* global dataLayer */

import { dollarStringToNumber } from 'helpers/money';
import {
  LEAD_EVENT,
  COMPLETE_EVENT,
  REACTIVATION_EVENT,
  POPULATE_DATA_LAYER,
  ADD_TO_CART_EVENT,
  BEGIN_CHECKOUT_EVENT,
} from 'lib/constants';

export default function googleTagManagerDataLayer() {
  return {
    name: 'gtmDataLayer',
    track: ({ payload }) => {
      if (payload.event === LEAD_EVENT) {
        const { email, externalId, mealPlanSlug } = payload.properties;
        dataLayer.push({
          event: 'GTM_LEAD',
          userSignedIn: true,
          userEmail: email,
          userUuid: externalId,
          mealPlanSlug,
        });
      } else if (payload.event === ADD_TO_CART_EVENT) {
        const { totalCents, meals, servings } = payload.properties;
        dataLayer.push({
          event: 'GTM_ADD_TO_CART',
          orderValue: totalCents / 100,
          meals: buildMeals({ meals, servings }),
        });
      } else if (payload.event === COMPLETE_EVENT) {
        const {
          value,
          credit,
          basket: { slug, meals = [], shipping, servings },
          orderSubtotal,
          voucherCode,
          user,
        } = payload.properties;
        dataLayer.push({
          event: 'GTM_COMPLETE',
          ecommerceItems: meals.map(meal => ({
            item_id: meal.id,
            item_name: meal.title,
            item_category: meal.ecommerceCategory,
            price: meal.price ? dollarStringToNumber(meal.price) * 2 : 0,
            quantity: servings / 2,
          })),
          orderValue: value,
          orderId: slug,
          orderSubtotal,
          wholeOrderDiscount: credit,
          maxWholeOrderDiscount: credit > orderSubtotal ? orderSubtotal : credit,
          meals: buildMeals({ meals, servings: user.servings }),
          numberOfMeals: user.weeklyMeals,
          hashedEmail: user.hashedEmail,
          voucherCode,
          shipping,
          tikTokContents: meals.map(meal => ({
            content_id: meal.id,
            content_name: meal.title,
            quantity: user.servings,
            price: meal.priceCents / 100,
          })),
        });
      } else if (payload.event === REACTIVATION_EVENT) {
        const { eventDeduplicationId, userUuid } = payload.properties;
        dataLayer.push({
          event: 'GTM_REACTIVATE',
          eventDeduplicationId,
          userUuid,
        });
      } else if (payload.event === BEGIN_CHECKOUT_EVENT) {
        const { totalCents, meals, servings } = payload.properties;
        dataLayer.push({
          event: 'GTM_BEGIN_CHECKOUT',
          orderValue: totalCents / 100,
          meals: buildMeals({ meals, servings }),
        });
      } else if (payload.event === POPULATE_DATA_LAYER) {
        dataLayer.push(payload.properties);
      }
    },
  };
}

const buildMeals = ({ meals = [], servings }) =>
  meals.map(meal => ({
    mealId: meal.id,
    mealTitle: meal.title,
    mealPrice: meal.priceCents / 100,
    mealQuantity: servings,
  }));
