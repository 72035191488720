import { GET_REACTIVATION } from 'actions/restartCampaigns';
import redirectToLogin from 'helpers/redirectToLogin';
import * as ErrorLogging from 'services/ErrorLogging';
import { FetchJsonError, FetchTypeError } from 'services/RequestService/helpers';

export const API_UNAUTHORIZED_ERROR = 'API_UNAUTHORIZED_ERROR';

const apiRequest = ({ requestType, params, metadata }) => ({
  type: `${requestType}_REQUEST`,
  requestType,
  params,
  metadata,
});

const apiResponse = ({ requestType, params, response, metadata }) => ({
  type: `${requestType}_RESPONSE`,
  requestType,
  response,
  params,
  metadata,
});

const apiError = ({ requestType, params, response, error, metadata }) => ({
  type: `${requestType}_ERROR`,
  requestType,
  params,
  response,
  error: { ...error, type: requestType },
  metadata,
});

const apiUnauthorized = () => ({
  type: API_UNAUTHORIZED_ERROR,
});

const logApiError = (response, requestType) => {
  if (response.status === 404 && requestType === GET_REACTIVATION) return;
  console.error(`${response.status} Error: ${JSON.stringify(response)}`); // eslint-disable-line no-console
};

export const makeApiRequest = ({
  requestType,
  method,
  params,
  metadata,
  redirectOnUnauthorized = true,
}) => {
  const requestParams = { requestType, params, metadata };

  return async dispatch => {
    dispatch(apiRequest(requestParams));

    let response;
    try {
      response = await method({ params });

      if (response.ok) {
        dispatch(apiResponse({ ...requestParams, response }));
      } else {
        logApiError(response, requestType);
        if (response.status == 401) {
          dispatch(apiUnauthorized());
          if (redirectOnUnauthorized) redirectToLogin();
          return response;
        }
        dispatch(apiError({ ...requestParams, response }));
      }
    } catch (error) {
      if (error instanceof FetchJsonError) {
        console.error(`Error parsing response for ${requestType}`); // eslint-disable-line no-console
      } else if (!(error instanceof FetchTypeError)) {
        ErrorLogging.captureException(error.message);
      }
      response = { ok: false, error };
      dispatch(apiError({ ...requestParams, error, response }));
    }

    return response;
  };
};
